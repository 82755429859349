<template>
  <div>
    <p class="text-2xl">
      Saldo
    </p>

    <v-divider class="mb-5" />

    <v-card class="mb-5">
      <v-card-text class="pa-3">
        <v-row>
          <v-col
            cols="12"
            md="6"
            sm="6"
          >
            <v-autocomplete
              v-model="filterUser"
              :items="users"
              label="Usuário"
              outlined
              dense
              hide-details
              item-text="value"
              item-value="id"
              @change="findUserTaxes"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            sm="6"
          >
            <date
              v-model="filterYearMonth"
              :type="'month'"
              outlined
              dense
              hide-details
              label="Mês Movimento"
              readonly
              @input="getExtract"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col
        v-if="dados.length"
        cols="12"
        sm="12"
      >
        <wallet-extract-statistics-card :dados="dados"></wallet-extract-statistics-card>
      </v-col>
      <v-col
        v-if="availableLimit >= 0"
        cols="12"
        sm="12"
      >
        <wallet-extract-balance-card :available-limit="availableLimit"></wallet-extract-balance-card>
      </v-col>
    </v-row>

    <p class="text-2xl mt-5">
      Movimentos
    </p>

    <v-divider class="mb-5" />

    <v-card class="mt-5">
      <v-data-table
        :headers="headers"
        :items="movements"
        dense
        item-key="id"
        class="table-rounded"
        disable-sort
      >
        <template #[`item.action`]="{ item }">
          <v-btn
            color="info"
            dark
            text
            title="Visualizar"
            x-small
            @click="setExtractDetail(item)"
          >
            <v-icon>{{ icons.mdiEye }}</v-icon>
          </v-btn>
        </template>
        <template #[`item.id`]="{ item }">
          #{{ resolveType(item.type).prefix }}{{ item.id }}
        </template>
        <template #[`item.type`]="{ item }">
          <v-icon :color="resolveType(item.type).color">
            {{ resolveType(item.type).icon }}
          </v-icon>
          {{ resolveType(item.type).text }}
        </template>
        <template #[`item.createdAt`]="{ item }">
          {{ $day(item.createdAt).format('DD/MM/YYYY HH:mm:ss') }}
        </template>
        <template #[`item.value`]="{ item }">
          {{ $money(item.value, 2) }}
        </template>
      </v-data-table>
    </v-card>

    <dialog-form
      :status="dialogExtractDetail"
      :title="`Movimento #${extractDetail.id}`"
      :width="1200"
      :hide-actions="true"
      @close="dialogExtractDetail = !dialogExtractDetail"
    >
      <v-row>
        <v-col
          cols="12"
          md="3"
          sm="6"
        >
          <v-text-field
            :value="$day(extractDetail.createdAt).format('DD/MM/YYYY HH:mm:ss')"
            disabled
            outlined
            hide-details
            dense
            label="Data"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          sm="6"
        >
          <v-text-field
            :value="resolveType(extractDetail.type).text"
            :append-icon="resolveType(extractDetail.type).icon"
            disabled
            outlined
            hide-details
            dense
            label="Tipo"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          sm="6"
        >
          <v-text-field
            :value="$money(extractDetail.value, 2)"
            disabled
            outlined
            hide-details
            dense
            label="Valor (R$)"
            prefix="R$"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headersDetail"
            :items="extractDetail.walletMovements"
            item-key="id"
            class="table-rounded"
            disable-sort
            dense
            hide-default-footer
          >
            <template #[`item.createdAt`]="{ item }">
              {{ $day(item.createdAt).format('DD/MM/YYYY HH:mm:ss') }}
            </template>
            <template #[`item.value`]="{ item }">
              {{ $money(item.value, 2) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-divider class="mb-3" />

      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            @click="dialogAddMovement = true"
          >
            Adicionar Movimento
            <v-icon
              dark
              right
            >
              {{ icons.mdiFilePlus }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </dialog-form>

    <dialog-form
      :status="dialogAddMovement"
      :title="'Adicionar Movimento'"
      :width="800"
      @action="addMovement"
      @close="dialogAddMovement = !dialogAddMovement"
    >
      <validation-observer
        ref="formAddMovement"
        autocomplete="off"
        tag="form"
      >
        <v-row>
          <v-col
            class="pb-0"
            cols="12"
            sm="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Tipo"
              rules="required"
              vid="extractMovement.type"
            >
              <v-select
                v-model="extractMovement.type"
                :error-messages="errors"
                :items="[
                  { value: 'input', text: 'Entrada' },
                  { value: 'output', text: 'Saída' },
                ]"
                label="Tipo"
                item-text="text"
                item-value="value"
                outlined
                dense
                :hide-details="!errors.length"
              />
            </validation-provider>
          </v-col>
          <v-col
            class="pb-0"
            cols="12"
            sm="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Valor"
              rules="required"
              vid="extractMovement.value"
            >
              <v-text-field
                v-model="extractMovement.value"
                :error-messages="errors"
                label="Valor"
                type="number"
                outlined
                dense
                :hide-details="!errors.length"
              />
            </validation-provider>
          </v-col>
          <v-col
            class="pb-0"
            cols="12"
            sm="12"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Motivo"
              rules="required|max:250"
              vid="extractMovement.description"
            >
              <v-textarea
                v-model="extractMovement.description"
                :error-messages="errors"
                label="Motivo"
                outlined
                dense
                :hide-details="!errors.length"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </validation-observer>
    </dialog-form>
  </div>
</template>

<script>
import {
  mdiEye,
  mdiFileDocument,
  mdiMessageTextOutline,
  mdiPhonePlusOutline,
  mdiFilePlus,
  mdiArrowUp,
  mdiArrowDown,
} from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import { ref } from '@vue/composition-api'

import WalletExtractStatisticsCard from '@/components/wallet-extract-statistics-card/WalletExtractStatisticsCard.vue'
import WalletExtractBalanceCard from '@/components/wallet-extract-balance-card/WalletExtractBalanceCard.vue'

export default {
  components: {
    WalletExtractStatisticsCard,
    WalletExtractBalanceCard,
  },
  computed: {
    ...mapState('customerTaxes', ['rows']),
    dados() {
      return this.rows.map(item => {
        const service = this.services.find(i => i.id === item.serviceId)

        return {
          title: service ? service.name : '',
          total: item.unitaryValue,
        }
      })
    },
  },
  setup() {
    const users = ref([])
    const filterUser = ref(null)
    const filterYearMonth = ref(null)
    const extractDetail = ref({})
    const extractMovement = ref({})
    const dialogExtractDetail = ref(false)
    const dialogAddMovement = ref(false)
    const total = ref([])
    const movements = ref([])
    const services = ref([])
    const availableLimit = ref(null)

    const resolveType = value => {
      if (value === 'recharge') {
        return {
          prefix: 'R',
          icon: mdiArrowUp,
          color: 'success',
          text: 'Recarga',
        }
      }

      return {
        prefix: 'C',
        icon: mdiArrowDown,
        color: 'error',
        text: 'Campanha',
      }
    }

    return {
      availableLimit,
      users,
      filterUser,
      filterYearMonth,
      extractDetail,
      extractMovement,
      dialogExtractDetail,
      dialogAddMovement,
      services,
      resolveType,
      icons: {
        mdiEye,
        mdiFileDocument,
        mdiMessageTextOutline,
        mdiPhonePlusOutline,
        mdiFilePlus,
      },
      headers: [
        { text: 'Ações', value: 'action', align: 'center' },
        { text: 'Transação', value: 'id' },
        { text: 'Tipo', value: 'type' },
        { text: 'Data', value: 'createdAt' },
        { text: 'Valor (R$)', value: 'value', align: 'right' },
      ],
      headersDetail: [
        { text: 'Data', value: 'createdAt' },
        { text: 'Descrição', value: 'description' },
        { text: 'Valor (R$)', value: 'value', align: 'right' },
      ],
      movements,
      total,
    }
  },

  async created() {
    await this.findUsers()
    await this.findServices()
  },

  beforeDestroy() {
    this.clearCustomerTaxes()
    this.services = []
    this.filterUser = null
  },

  methods: {
    ...mapActions('customerTaxes', {
      findCustomerTaxes: 'find',
      clearCustomerTaxes: 'clearCustomerTaxes',
    }),
    ...mapActions('services', {
      fetchServices: 'find',
    }),
    ...mapActions('user', {
      fetchUsers: 'find',
    }),
    ...mapActions('walletExtract', {
      fechWalletExtract: 'find',
      createMovement: 'create',
    }),

    setExtractDetail(item) {
      this.extractDetail = item
      this.dialogExtractDetail = true
    },

    async findUserTaxes() {
      this.getExtract()
      await this.findCustomerTaxes({ userId: this.filterUser })
    },

    async findUsers() {
      const users = await this.fetchUsers()

      this.users = users.map(user => ({
        ...user,
        value: `${user.document ? String(this.$cpfCnpj.toMask(user.document)).concat(' - ', user.name) : user.name}`,
      }))
    },

    async findServices() {
      this.services = await this.fetchServices()
    },

    async getExtract() {
      const { total, wallets } = await this.fechWalletExtract({
        userId: this.filterUser,
        yearMonth: this.filterYearMonth ? this.$day(this.filterYearMonth, 'MM/YYYY').format('YYYY-MM') : undefined,
      })
      this.availableLimit = total.availableLimit

      this.movements = wallets
    },

    async addMovement() {
      const body = {
        walletId: this.extractDetail.id,
        description: this.extractMovement.description,
        value: Number(this.extractMovement.value),
      }

      body.value = this.extractMovement.type === 'output' ? -body.value : body.value

      if (await this.$refs.formAddMovement.validate()) {
        const status = await this.createMovement(body)

        if (status === 201) {
          await this.getExtract()
          const item = this.movements.find(movement => movement.id === this.extractDetail.id)
          this.setExtractDetail(item)

          this.extractMovement.type = null
          this.extractMovement.description = null
          this.extractMovement.value = null
          this.dialogAddMovement = false
        }
      }
    },
  },
}
</script>
